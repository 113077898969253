import "@stimulus/polyfills";
import "@babel/polyfill";
import "whatwg-fetch";
import { Application } from "stimulus";

/*import { definitionsFromContext } from "stimulus/webpack-helpers";*/
//https://github.com/danieldiekmeier/stimulus-controller-resolver
import StimulusControllerResolver from 'stimulus-controller-resolver';

const application = Application.start();

//All controllers into main bundle
//const context = require.context("./controllers", true, /\.js$/);
//application.load(definitionsFromContext(context));

//Load controllers lazily
StimulusControllerResolver.install(application, async controllerName => (
    (await import(`./controllers/${controllerName}-controller.js`)).default
))

/* Only register a service worker if it's supported */
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js');
}